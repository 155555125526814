/* eslint-disable @next/next/no-img-element */
import { useQuery } from '@apollo/client';
import {
  Box,
  Stack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import React, { RefObject, useState } from 'react';
import { GET_INSURANCE_PLANS } from '../../api/query/device-care';
import { getFaintBgByPartner } from '../../components/homepage/partner-config';
import { FullPageSpinner } from '../../components/primitives';
import { isAIR } from '../../utils/partnerTheme';
import PlanCard from './plan-card';

const duration = [
  { id: 1, type: 'YEARLY' },
  { id: 0, type: 'MONTHLY' },
  ...(isAIR
    ? [
        { id: 2, type: 'WEEKLY', isModal: true },
        { id: 3, type: 'DAILY', isModal: true },
      ]
    : []),
];

const tabListStyles = {
  width: '100%',
  padding: '4px',
  marginBottom: '20px',
  borderBottom: 'none !important',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    display: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    display: 'none',
  },
};

const tabStyles = {
  borderBottom: 'none !important',
  background: '#dddddd',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  // padding: '10px',
  paddingInline: ['0', '30px'],
  active: {
    background: '#C8C8C8',
  },
  '@media(max-width: 48em)': {
    paddingInline: '20px',
  },
};

const getTextByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'My MTNPicknFix';
    case 'AIRNG':
      return 'Airtel PicknFix';
    default:
      return 'PicknFix';
  }
};

const getHeaderByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'My MTN';
    case 'AIRNG':
      return 'airtel';
    default:
      return 'PNF';
  }
};

const getColorByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return '#ffcb05';
    case 'AIRNG':
      return '#ffdfdf';
    default:
      return '#0F61D6';
  }
};

const ppViews = process.env.NEXT_PUBLIC_PP_VIEWS;

const ProtectionPlan = ({
  sectionRef,
}: {
  sectionRef: RefObject<HTMLDivElement>;
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const { loading: loadingPlans, data: plans } = useQuery<{
    insurance_plan: {
      id: string;
      description: string;
      price: string;
      name: string;
      planType: string;
      plan_info: string[];
      plan_ussd_code: string;
      cover_value: string;
      plan_tnc: string;
    }[];
  }>(GET_INSURANCE_PLANS, {
    variables: { partnerCode: process.env.NEXT_PUBLIC_PARTNER_CODE },
  });

  return (
    <Box pt={5} ref={sectionRef} id="protection-plan">
      {/* <Navbar /> */}
      {loadingPlans && <FullPageSpinner />}

      {ppViews?.includes('one') && (
        <Box as="section" className="section" id="protection-plan">
          <Box className="container---main">
            <Box
              data-w-id="cfeb3857-bca4-2696-44bc-e64f0eedc347"
              className="grid-halves"
            >
              <Box
                id="w-node-cfeb3857-bca4-2696-44bc-e64f0eedc34a-df636a8f"
                className="container---s"
              >
                <Box className="multipurpose-text-section">
                  <Box className="heading-one">
                    Protect the devices you love
                  </Box>
                  <Box className="horizontal-icon-features">
                    <Box className="horizontal-icon-feature">
                      <img
                        src="images/interface-icon-check.svg"
                        alt="Checkmark icon"
                        className="horizontal-icon-feature-image"
                      />
                      <Box className="text-block-18">
                        <strong className="bold-text-5">
                          Affordable Protection Plans
                        </strong>
                      </Box>
                    </Box>
                    <Box className="horizontal-icon-feature">
                      <img
                        src="images/interface-icon-check.svg"
                        alt="Checkmark icon"
                        className="horizontal-icon-feature-image"
                      />
                      <Box className="text-block-18">
                        <strong className="bold-text-5">
                          Covers brand new and used devices
                        </strong>
                      </Box>
                    </Box>
                    <Box className="horizontal-icon-feature">
                      <img
                        src="images/interface-icon-check.svg"
                        alt="Checkmark icon"
                        className="horizontal-icon-feature-image"
                      />
                      <Box className="text-block-20">
                        <strong className="bold-text-6">
                          Fast repairs with Manufacturer Approved Service
                          Centers
                        </strong>
                      </Box>
                    </Box>
                    <Box className="horizontal-icon-feature">
                      <img
                        src="images/interface-icon-check.svg"
                        alt="Checkmark icon"
                        className="horizontal-icon-feature-image"
                      />
                      <Box className="text-block-19">
                        <strong>Hassle-free Claim Process</strong>
                      </Box>
                    </Box>
                  </Box>
                  <Link
                    href="/device-care/plan"
                    className="button bg-gray-dark w-inline-block"
                  >
                    <Box className="button-text text-primary-3b">
                      Explore Plans
                    </Box>
                    <Box className="button-effect bg-white"></Box>
                  </Link>
                </Box>
              </Box>
              <Box className="widget composite">
                <img
                  src="images/photo-square-03_compressed.webp"
                  sizes="(max-width: 479px) 89vw, 428px"
                  width="758"
                  height="1004"
                  alt=""
                  srcSet="images/photo-square-03_compressed-p-500.webp 500w, images/photo-square-03_compressed-p-800.webp 800w, images/photo-square-03_compressed.webp 1000w"
                />
                <Box className="widget-notification">
                  <Box className="widget-notification-title">
                    <Box className="widget-icon-circle">
                      <img
                        src="images/decorative-icon-junk-food-dark.svg"
                        alt=""
                        width="48"
                        height="48"
                        className="widget-icon-circle-icon"
                      />
                    </Box>
                    <Box>
                      <Box className="small-text text-bold">
                        Drinks at Yonder
                      </Box>
                      <Box className="small-text muted">$37.50</Box>
                    </Box>
                  </Box>
                  <Box className="small-text muted">11:30am</Box>
                </Box>
                <Box className="widget-notification">
                  <Box className="widget-notification-title">
                    <Box className="widget-icon-circle">
                      <img
                        src="images/interface-icon-check.svg"
                        alt=""
                        width="48"
                        height="48"
                        className="widget-icon-circle-icon"
                      />
                    </Box>
                    <Box>
                      <Box className="small-text text-bold">
                        Bronze Monthly Plan b
                      </Box>
                      <Box className="small-text muted">N850</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {ppViews?.includes('two') && (
        <Box
          as="section"
          className="section protection-plan"
          bg={getFaintBgByPartner()}
        >
          <Box className="container---main" maxW="1400px" mx="auto">
            <Box className="product-features-grid-section">
              <Box className="heading-one text-center">
                Protection Cover for all your devices
              </Box>
              <Tabs
                onChange={(index) => {
                  setActiveTab(index);
                }}
              >
                <TabList className="tabs-menu w-tab-menu" css={tabListStyles}>
                  <Box
                    w="full"
                    display="flex"
                    justifyContent={['center', 'flex-end']}
                    data-current="Yearly"
                    data-easing="ease"
                    data-duration-in="300"
                    data-duration-out="100"
                    className="w-tabs"
                  >
                    {duration.map((dur) => (
                      <Tab
                        key={dur.id}
                        aria-label={dur.type}
                        className="w-inline-block w-tab-link"
                        _selected={tabStyles.active}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        css={tabStyles}
                      >
                        {dur.type.toLowerCase()}
                      </Tab>
                    ))}
                  </Box>
                </TabList>
                <TabPanels>
                  <Box className="w-tab-content">
                    <Box className="product-grid-thirds">
                      {plans?.insurance_plan
                        ?.filter(
                          (plan) => plan.planType === duration[activeTab].type
                        )
                        ?.map((plan) => (
                          <PlanCard
                            key={plan.id}
                            plan={plan}
                            duration={duration}
                            activeTab={activeTab}
                          />
                        ))}
                    </Box>
                  </Box>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      )}

      {ppViews?.includes('three') && (
        <Box as="section" className="section">
          <Box className="container---main">
            <Box className="feature-grid-section">
              <Box className="heading-two feature-grid-heading">
                The best device and repair protection service in the market.
              </Box>
              <Box className="small-features-grid-thirds">
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Fast and reliable</Box>
                  </Box>
                  <Box className="text-block-2">
                    Fastest repair Turn Around Time by manufacturer approved
                    after sales support services centres
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Quality assured</Box>
                  </Box>
                  <Box className="text-block-3">
                    All repairs undertaken according to high standards set by
                    your device’s manufacturer. Quality repairs delivered every
                    time
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Unrivalled delivery</Box>
                  </Box>
                  <Box className="text-block-4">
                    Access 24/7 repairs updates through multiple channels so you
                    know exactly when your device is coming back to you
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Manufacturer Approved SC</Box>
                  </Box>
                  <Box className="text-block-7">
                    Every single service center on the PicknFix network has been
                    approved and vetted by the manufacturer of your device to
                    offer in and out of warranty services.
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Varieties of devices</Box>
                  </Box>
                  <Box className="text-block-6">
                    Fix Desktop, Laptop Computers, Routers, Tablets, Mobile
                    Phones, TVs, Mifis, Printers, Monitors, Gaming Consoles,
                    Microwaves, Cameras, Scanners and many more
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Make Claims</Box>
                  </Box>
                  <Box className="text-block-5">
                    Make claims in simple steps from the PicknFix mobile app.
                    Easy and effortless.
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {ppViews?.includes('four') && (
        <Box as="section" className="section uber-section">
          <Stack align="center" maxW="1200px" marginX="auto">
            <Text className="heading-two large-quote">
              &quot; {getTextByPartner()} App gives me the kind of convenience
              of using Uber. Excellent stuff &quot;
            </Text>
            <img
              src="images/photo-avatar-02_compressed.webp"
              alt=""
              className="quote-author-image"
            />
            <Box>
              <strong>Olu Jacobs, Lagos Nigeria</strong>
            </Box>
          </Stack>
        </Box>
      )}

      {/* <Footer /> */}
    </Box>
  );
};

export default ProtectionPlan;
